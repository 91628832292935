/* Main app */
.header {
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 0px !important;
  justify-content: space-between;
  height: auto;
}

.content {
  background-color: #f3f3f3;
}

.trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sider */
.sider {
  height: 100vh;
  position: sticky !important;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
}

.sider::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.sider::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.sider::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

.logo {
  color: #4f6f52;
  margin: 10px 0;
  font-size: 1.5rem;
}
.menu-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  /* font-size: 1rem; */
}

.ant-menu-item-selected {
  background-color: #1677ff !important;
  color: #ffff !important;
}
.ant-menu-title-content {
  color: #a9a6a6;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: #ffff !important;
}
.page-content {
  margin: 24px 16px;
  padding: 20px;
}
.btn {
  background-color: #c9c9c9 !important;
}

.menu-icon {
  font-size: 18px !important;
  color: #8c8989 !important;
}
.ant-menu-item-selected .menu-icon {
  color: #ffff !important;
}

.ant-menu-submenu-arrow {
  color: #8c8989 !important;
}
.ant-menu-item-selected .ant-menu-submenu-arrow {
  color: #8c8989 !important;
}

.ant-menu-submenu-open .ant-menu-submenu-arrow {
  color: #8c8989 !important;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.menu-bar .ant-menu-title-content:hover,
.menu-bar .ant-menu-submenu-title:hover {
  color: #ffff !important;
}

.menu-bar .menu-icon:hover {
  color: #b2f2b2 !important;
}

.custom-button:hover {
  background-color: transparent !important;
  border-radius: 4px;
}

.ant-layout-sider-children,
.ant-menu {
  background: #151529 !important;
}

.ant-table-cell {
  padding: 10px !important;
}
